import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Layout, Typography, Alert, Space, QRCode } from 'antd'
import { LeftBar } from '../componentes/leftBar';
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';
import ReactToPrint from 'react-to-print';


const { Content } = Layout

export const Declaracao = (props) => {

    const componentRef = useRef();

    const [numero, setNumero] = useState('')
    const [crq, setCrq] = useState('')
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()
    const [data, setData] = useState(undefined)

    async function consulta() {
        try {
            if (!numero || !crq) {
                setData(undefined)
                addToast("Informe todos os campos!", { appearance: 'error', autoDismiss: true });
                return
            }

            setLoading(true)
            const result = await api.post('emitir', { CPFCNPJ: numero, CRQ: crq })
            console.log(result.data)
            setData(result.data)
            if (result.data.length === 0) {
                addToast("Não foi localizado nenhum registro!", { appearance: 'error', autoDismiss: true });
            }
        } catch (erro) {
            addToast(erro.message, { appearance: 'error', autoDismiss: true });
        }
        setLoading(false)
    }


    function getSheetMes(mesNumber) {
        mesNumber = mesNumber + 1
        var mes = 'Janeiro';
        switch (mesNumber) {
            case 1:
                mes = 'janeiro';
                break;
            case 2:
                mes = 'fevereiro';
                break;
            case 3:
                mes = 'março';
                break;
            case 4:
                mes = 'abril';
                break;
            case 5:
                mes = 'maio';
                break;
            case 6:
                mes = 'junho';
                break;
            case 7:
                mes = 'julho';
                break;
            case 8:
                mes = 'agosto';
                break;
            case 9:
                mes = 'setembro';
                break;
            case 10:
                mes = 'outubro';
                break;
            case 11:
                mes = 'novembro';
                break;
            case 12:
                mes = 'dezembro';
                break;

            default:
                break;
        }
        return mes;
    }


    function renderConsultar() {
        return <React.Fragment>
            <Typography.Title level={3}>Emissão de Declaração</Typography.Title>
            <br />
            <span>Número do CRQ</span>
            <Input placeholder='CRQ'
            
                block
                style={{ textAlign: 'center', marginBottom:10 }}
                value={crq}
                onChange={event => {
                    setCrq(event.target.value)
                }} />
           

            5 primeiros dígitos do CPF/CNPJ
            <Input placeholder='CPF'
                block style={{ textAlign: 'center' }}
                value={numero}
                onChange={event => {
                    setNumero(event.target.value)
                }} />

           
            <Button type="primary" style={{marginTop:15}} loading={loading} block onClick={() => consulta()}>
                Consultar
            </Button>
           
            {
                data?.length === 0 && <Alert style={{marginTop:15}} message="Não foi localizado nenhum registro para os dados informados." type="error" />
            }
        </React.Fragment>
    }


    function renderHeader() {
        return <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textAlign:'center' }}>
            <img alt="brazao" src="http://crqx.org.br/transparencia/imagens/brasao.png" height="100px" width="100px"
                style={{ justifyContent: 'center', justifyItems: 'center' }} />
            <p> <strong> SERVIÇO PÚBLICO FEDERAL  <br />
                CONSELHO REGIONAL DE QUÍMICA DA 10ª REGIÃO
            </strong> <br />
                Rua Floriano Peixoto Nº 2.020 CEP: 60.025-131 Bairro José Bonifácio<br />
                Fortaleza-Ceará -  Fone: (85) 3226-4958 / (85) 3253-1607 <br />
                E-mail: crqx@crqx.org.br - site: www.crqx.org.br <br /><br />
            </p>
            <h3> DECLARAÇÃO DE QUITAÇÃO</h3>
            <br /><br />



        </div>
    }



    function renderResultado() {

        
        if (data.length > 0) {
            var now = new Date()
            var NOME = data[0].NOME;
            var SEXO = data[0].SEXO;
            var DIPLOMA = data[0].CATEGORIA;
            var VALIDADE = '31 de março de '.concat(parseInt(data[0].UAP) + 1);
            var UAP = data[0].UAP;
            var CNPJ = data[0].CNPJ;
            var BAIRRO = data[0].BAIRRO;
            var MUNICIPIO = data[0].CIDADE;
            var ENDERECO = data[0].ENDERECO;
            var CEP = data[0].CEP;
            var token = data[0].token; //create_UUID();

            return <div style={{width:'19cm'}}>
                <div ref={componentRef} style={{textAlign:'center'}}>
                    {/*  tipo === 0 é profissional */}
                    {data[0].tipo === 0 ?
                        <div style={{ textAlign:'center', marginLeft:50, marginRight:50 }}>
                            {renderHeader()}
                            {"Declaramos para fins de prova que  "}
                            {SEXO === 'F' ? 'a ' : 'o '}{DIPLOMA},<b> {NOME} </b>
                            {" está regularizad".concat(SEXO === 'F' ? 'a ' : 'o ')}

                            {"no Conselho Regional de Química 10ª Região sob o Nº "} {crq} {', tendo efetuado '}
                            {' pagamento da anuidade do exercício de '} {UAP} {', estando em dia com suas obrigações parafiscais.'}
                            <br /> {"Esta declaração não contém emendas, rasuras e entrelinhas e tem a validade até "} <br />
                            {VALIDADE}<br /> {'Assino a presente declaração para que produza os devidos efeitos legais.'} <br /><br />
                            {'Fortaleza, '} {now.getDate()} {' de '} {getSheetMes(now.getMonth())} {' de '} {now.getFullYear()}.

                        </div> :
                        <div style={{ textAlign:'center', marginLeft:50, marginRight:50 }}>
                             {renderHeader()}
                            Declaramos para fins de prova que  a empresa
                            <b> {NOME} </b>, inscrita no CNPJ sob o Nº {CNPJ} com endereço {ENDERECO}
                            BAIRRO: {BAIRRO} - CEP: {CEP} no município de {MUNICIPIO} estado do Ceará,
                            está registrado no Conselho Regional de Química-10ª Região, sob o N° {crq} e quite com suas
                            obrigações parafiscais até o exercício de {UAP}.  <br />Essa declaração não contém emendas,
                            rasuras e entrelinhas e tem validade até {VALIDADE}.  
                            <br />Assino a presente declaração para que produza os devidos efeitos legais. <br /><br /> 
                            Fortaleza, {now.getDate()} de  {getSheetMes(now.getMonth())} de {now.getFullYear()}.

                        </div>
                    }
                    <br />
                    <br /><br />AUTENTICAÇÃO: <b><br /> {token}
                    </b><br /><br />Para comprovar a autenticidade eletrônica desta declaração acesse:<br />
                    www.crqx.org.br/transparencia/declaracao-autenticacao ou escaneie o QRCode <br /><br />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <QRCode
                            value={"http://www.crqx.org.br/transparencia/declaracao-autenticacao/?CODE=".concat('', token)} />
                    </div>

                </div>
                <Space direction='horizontal' style={{ marginTop: 30 }}>
                    <Button key="buy" onClick={() => setData(undefined)}>Voltar</Button>
                    <ReactToPrint
                        trigger={() => <Button key="buy" type='primary' onClick={() => setData(undefined)}>Imprimir</Button>
                        }
                        content={() => componentRef.current}
                    />                    
                </Space>
            </div>
        } else {

            return <div>
                Não foi possível gerar a declaração para o CRQ <b>{crq}</b>,
                verifique se os dados estão corretos ou se o profissional e / ou firma está em situação
                regular junto ao CRQ - X.
            </div >
        }
    }


    return (
        <Layout style={{ height: '100vh', maxHeight: '100vh', width:'100%' }}>
            <Layout>
                <LeftBar selected={"4"}/>
                <Content
                    style={{
                        margin: '24px 16px 0',
                        overflow: 'auto',
                        width:'100%'
                    }}>
                    <div
                        style={{
                            justifyContent: 'center',
                            padding: 24,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>

                        <div style={{
                            display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            {data?.length > 0 ? renderResultado() : renderConsultar()}
                        </div>
                        <br /><br />


                    </div>
                </Content>

            </Layout>
        </Layout>


    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Declaracao)