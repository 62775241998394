import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Layout, Typography, Alert, Result, Row, Col } from 'antd'
import { LeftBar } from '../componentes/leftBar';
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';


const { Content } = Layout


export const ValidarDeclaracao = (props) => {

    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()
    const [data, setData] = useState(undefined)

    async function consulta() {
        try {

            if (!code) {
                addToast("Informe o código de autenticação!", { appearance: 'error', autoDismiss: true });
                return
            }

            setLoading(true)
            const result = await api.get('validar?code='.concat(code))
            console.log(result.data)
            setData(result.data)
            if (result.data.length === 0) {
                addToast("Não foi localizado nenhum registro!", { appearance: 'error', autoDismiss: true });
            }
        } catch (erro) {
            addToast(erro.message, { appearance: 'error', autoDismiss: true });
        }
        setLoading(false)
    }


    function renderConsultar() {
        return <React.Fragment>
            <Typography.Title level={3}>Validar Declaração</Typography.Title>
            <br />
            <Typography.Text>
                Informe o código de autenticação gerado na declaração emitida eletronicamente no campo abaixo.
            </Typography.Text>
            <Input placeholder='Código de Autenticação' style={{ textAlign: 'center' }}
                value={code}
                onChange={event => {
                    setCode(event.target.value)
                }} />

           
            <Button type="primary" loading={loading} style={{ width: 150, marginTop:15 }} onClick={() => consulta()}>
                Validar
            </Button>
           
            {
                data?.length === 0 && <Alert style={{marginTop:15}} message="Não foi localizado nenhum registro para os dados informados." type="error" />
            }
        </React.Fragment>
    }


    function renderResultado() {
        return <React.Fragment>
            <Result
                style={{ width: 800 }}
                status="success"
                title="Declaração Validada com sucesso!"
                subTitle={<div> </div>}
                extra={[
                    <Alert message={<Row gutter={[8, 8]} >
                    <Col span={10} style={{ textAlign: 'right' }}>Validade: </Col>
                    <Col span={14} style={{ textAlign: 'left', fontWeight: 700 }}> {data[0].validade}</Col>
                    <Col span={10} style={{ textAlign: 'right' }}>Nome: </Col>
                    <Col span={14} style={{ textAlign: 'left', fontWeight: 700 }}>{data[0].NOME}</Col>
                    <Col span={10} style={{ textAlign: 'right' }}>CPF/CNPJ: </Col>
                    <Col span={14} style={{ textAlign: 'left', fontWeight: 700 }}>{data[0].CNPJ}</Col>
                    <Col span={10} style={{ textAlign: 'right' }}>CRQ: </Col>
                    <Col span={14} style={{ textAlign: 'left', fontWeight: 700 }}>{data[0].crq}</Col>
                    <Col span={10} style={{ textAlign: 'right' }}>Autenticação: </Col>
                    <Col span={14} style={{ textAlign: 'left', fontWeight: 700 }}>{data[0].auth}</Col>
                </Row>}>
                        
                    </Alert>,
                    <br />,
                    <Button key="buy" onClick={() => setData(undefined)}>Voltar</Button>
                ]}
            />
        </React.Fragment>
    }


    return (
        <Layout style={{ height: '100vh', width: '100%' }}>
            <Layout>
                <LeftBar selected={"5"}/>
                <Content
                    style={{
                        margin: '24px 16px 0'
                    }}>
                    <div
                        style={{
                            justifyContent: 'center',
                            padding: 24,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>

                        <div style={{
                            display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            maxWidth: '90%', alignItems: 'center'
                        }}>
                            {data?.length > 0 ? renderResultado() : renderConsultar()}
                        </div>
                        <br /><br />


                    </div>
                </Content>

            </Layout>
        </Layout>


    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ValidarDeclaracao)