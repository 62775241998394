import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input, Typography } from 'antd';
import { Link } from "react-router-dom";
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';
import StringMask from "string-mask";

const DELIMITERS = [".", "-", "/"];
const CNPJ_MASK = "00.000.000/0000-00";
const CPF_MASK = "000.000.000-00";

function removeTrailingCharIfFound(str, chars) {
    let result = str;

    chars.forEach(c => {
        if (str[str.length - 1] === c) {
            result = str.slice(0, str.length - 1);
        }
    });

    return result;
}

export const AreaRegistradoForgot = (props) => {

    const { addToast } = useToasts()
    const [cpfcnpj, setCpfCnpj] = useState('') //091.898.903-53
    const [email, setEmail] = useState('')
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)

    function formatValue(str) {
        const unmaskedValue = str.replace(/[.\-\/]/g, "").trim();
        let mask = CPF_MASK;
        if (unmaskedValue.length > 11)
            mask = CNPJ_MASK;
        const formatted = StringMask.process(unmaskedValue, mask);
        return removeTrailingCharIfFound(formatted.result, DELIMITERS);
    }

    function renderAlert() {
        if (!data)
            return <div></div>
        if (data.email) {
            return <Alert type='info' style={{ marginTop: 10 }} message={
                <p>
                    {"Sua senha foi enviada para o e-mail "} <strong>{email}</strong>.<br />
                </p>
            }>
            </Alert>
        } else {
            return <Alert type='warning' style={{ marginTop: 10 }} message={
                <p>
                    {"Registrado não localizado ou não possui email cadastrado no CRQ-X. Para mais informações entre em contato."} .<br />
                    E-mail: crqce@hotmail.com<br />
                    Telefone: 3226-4958 / 3085-3775
                </p>
            }>
            </Alert>
        }
    }


    async function consulta() {
        try {

            if (!cpfcnpj) {
                setData(undefined)
                addToast("Informe o campo CPF/CNPJ do registrado!", { appearance: 'error', autoDismiss: true });
                return
            }

            setLoading(true)
            const result = await api.post('forgot', { cpfcnpj: cpfcnpj })
            console.log(result.data)

            if (result.data.email) {
                var emailtemp = result.data.email.split('@')
                if (emailtemp.length > 1) {
                    var tp = emailtemp[0]
                    if (tp.length > 7) {
                        tp = tp.substring(0, 6).concat('****@', emailtemp[1])
                        setEmail(tp);
                    }
                }
                setData(result.data)
            } else {
                setData({
                    status: 200,
                    text: 'Registrado não possui email cadastrado no CRQ-X'
                })
            }
        } catch (erro) {
            addToast(erro.message, { appearance: 'error', autoDismiss: true });
        }
        setLoading(false)
    }


    return (<div style={{
        display: 'flex', justifyContent: 'center',
        backgroundColor: 'whitesmoke', height: '100vh', maxHeight: '100vh'
    }}>
        <div style={{
            display: 'flex', flexDirection: 'column', backgroundColor: 'white',
            alignItems: 'center', maxWidth: 450, width: 450, justifyContent: 'center', margin: 'auto'
        }}>
            <div
                name="normal_login2"
                className="login-form"
                style={{ margin: 25 }}>
                <div>
                    <img style={{ height: 110, width: 110, marginTop: 10 }}
                        alt='logo'
                        src="https://crqx.org.br/downloads/4-decadas-quadrada-branco.png" />

                    <Typography.Title level={4}>Recuperação de Senha</Typography.Title>
                    <Typography.Text>
                        A senha será enviada para o e-mail cadastrado junto ao CRQ-X. Caso não recebe a senha em até 15 minutos
                        entre em contato com o CRQ-X para atualização de seus dados.
                    </Typography.Text>

                </div>

                {
                    renderAlert()
                }

                <div name="crqx" style={{ marginTop: 20 }}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Informe o nº do CPF/CNPJ!"
                        name='senha5'
                        autoComplete="off"
                        value={cpfcnpj}
                        onChange={(e) => setCpfCnpj(formatValue(e.target.value))} />
                </div>


                <Button style={{ marginTop: 30 }}
                    type="primary"
                    loading={loading}
                    onClick={() => consulta()} className="login-form-button">
                    Enviar e-mail
                </Button>

                <div style={{ marginTop: 15 }}>
                    <Link to={"/registrado/login"}>
                        Voltar
                    </Link>
                </div>

                <div style={{ fontSize: '0.6em', marginTop: 10 }}>
                    1983-2023
                </div>


            </div>
        </div>
    </div>
    );


}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AreaRegistradoForgot)