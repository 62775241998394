import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Layout, Typography, Alert, Result, Space, Divider } from 'antd'
import { LeftBar } from '../componentes/leftBar';
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';
import dayjs from 'dayjs'


const { Content } = Layout

export const ConsultaART = (props) => {

    const [ano, setAno] = useState(dayjs().format('YYYY'))
    const [numero, setNumero] = useState('')
    const [crq, setCrq] = useState('')
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts()
    const [data, setData] = useState(undefined)

    async function consulta() {
        try {

            if(!ano || !numero || !crq){
                setData(undefined)
                addToast("Informe todos os campos!", { appearance: 'error', autoDismiss: true });
                return 
            }

            setLoading(true)
            const result = await api.post('consulta-art', { ano: ano, numero: numero, crqx: crq })
            console.log(result.data)
            setData(result.data)
            if (result.data.length === 0) {
                addToast("Não foi localizado nenhum registro!", { appearance: 'error', autoDismiss: true });
            }
        } catch (erro) {
            addToast(erro.message, { appearance: 'error', autoDismiss: true });
        }
        setLoading(false)
    }


    function renderConsultar() {
        return <React.Fragment>
            <Typography.Title level={3}>Consulta de ART</Typography.Title>
            <br />
            <Typography.Text>A consulta é feita para ART`s dentro do período de validade.</Typography.Text>
            <br />
            <Space direction='horizontal'>
                <Input placeholder='Número' style={{ textAlign: 'center' }}
                    value={numero}
                    onChange={event => {
                        setNumero(event.target.value)
                    }} />

                <Input placeholder='Ano' style={{ textAlign: 'center' }}
                    value={ano}
                    onChange={event => {
                        setAno(event.target.value)
                    }} />
            </Space>
            
            <Input placeholder='Nº do CRQ do profissional' style={{ textAlign: 'center', marginTop:10 }}
                value={crq}
                onChange={event => {
                    setCrq(event.target.value)
                }} />
            
            <Button type="primary" style={{marginTop:15}} loading={loading} block onClick={() => consulta()}>
                Consultar
            </Button>
            <br />
            {
                data?.length === 0 && <Alert style={{marginTop:15}} message="Não foi localizado nenhum registro para os dados informados." type="error" />
            }
        </React.Fragment>
    }


    function renderResultado() {
        return <React.Fragment>
            <Result
                status="success"
                title={<span>DADOS DA ART </span>}
                subTitle={""}
                extra={[
                    <Alert type='info' description={
                        <Space direction='vertical'>
                            <Space direction={'horizontal'}>
                                Nº :
                                <strong> {data[0].numero} </strong>
                                <span style={{ marginLeft: 5 }}>ANO: </span>
                                <strong> {data[0].ano} </strong>
                            </Space>
                            <Space direction={'horizontal'}>
                                Validade até:
                                <strong> {data[0].data} </strong>
                            </Space>
                            {
                                !data[0].cnpj.includes("_") && <Space direction={'horizontal'}>
                                    Para a empresa:
                                    <strong> {data[0].cnpj} </strong>
                                </Space>
                            }
                            <Divider style={{marginBottom:5, marginTop:5}}/>
                            <Space>
                                {data[0].tipo}
                            </Space>
                            <Divider style={{marginBottom:5, marginTop:5}}/>
                            {
                                data[0].baixada === 1 && <Space direction={'horizontal'}>
                                    <span style={{color:'red'}}> ART BAIXADA </span>
                                </Space>
                            }
                        </Space>
                    }>

                    </Alert>,
                    <br />,
                    <Button key="buy" onClick={() => setData(undefined)}>Voltar</Button>
                ]}
            />
        </React.Fragment>
    }


    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <LeftBar selected={"3"}/>
                <Content
                    style={{
                        margin: '24px 16px 0'
                    }}>
                    <div
                        style={{
                            justifyContent: 'center',
                            padding: 24,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>

                        <div style={{
                            display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            maxWidth: 500, alignItems: 'center'
                        }}>
                            {data?.length > 0 ? renderResultado() : renderConsultar()}
                        </div>
                        <br /><br />


                    </div>
                </Content>

            </Layout>
        </Layout>


    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaART)