import axios from "axios";

export const BASEURL =   "https://servicos.crqx.org.br/"


const axiosApi = axios.create({

  baseURL: BASEURL
});

export default axiosApi;