import React from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Divider } from 'antd'
import { Link } from "react-router-dom";
import {background_color} from '../service/estilo'
const { Sider } = Layout


export const LeftBar = (props) => {
    return <Sider
        style={{ backgroundColor: background_color }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
            console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
        }}>
        <div className="logo" >
            <img style={{ height: 110, width: 110, marginTop: 10 }}
                alt='logo'
                src="http://crqx.org.br/downloads/4-decadas-quadrada-branco.png" />
        </div>
        <span style={{ color: '#fff', fontSize: '1.8em' }}>CRQ-X</span><br />
        <Link to="/" >
            <span style={{ color: 'yellow', fontSize: '0.8em' }}>SERVIÇOS ONLINE</span>
        </Link>
        <Divider style={{ marginBottom: 0 }} />
        <Menu
            style={{ backgroundColor: background_color }}
            theme="dark"
            mode="inline"
            selectedKeys={[props.selected]}>
            <Menu.Item key={"1"}>
                <Link to="/consulta-profissional" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Consulta Profissional  </Link>
            </Menu.Item>
            <Menu.Item key={"2"}>
                <Link to="/consulta-firma" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Consulta Firma  </Link>
            </Menu.Item>
            <Menu.Item key={"3"}>
                <Link to={"/consulta-art"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Consulta ART  </Link>
            </Menu.Item>
            <Menu.Item key={"4"}>
                <Link to={"/declaracao"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Declaração de Quitação  </Link>
            </Menu.Item>
            <Menu.Item key={"5"}>
                <Link to={"/declaracao-autenticar"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Validar Declaração
                </Link>
            </Menu.Item>
            <Menu.Item key={"6"}>
                <Link to={"/consulta-CRT"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start' }}>
                    Consulta C.R.T
                </Link>
            </Menu.Item>
        </Menu>
    </Sider>

}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar)