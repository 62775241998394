import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { userState } from '../service/database'
import { useRecoilState } from 'recoil'
import { Button, Space, Typography, Row, Col, Card, Input, Divider, Modal, Alert } from 'antd'
import { background_color } from '../service/estilo'
import { useNavigate, Link } from 'react-router-dom'
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export const AreaRegistrado = (props) => {

  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [senha1, setSenha1] = useState('')
  const [senha2, setSenha2] = useState('')
  const [senha3, setSenha3] = useState('')
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const [msg, setMsg] = useState(undefined)

  useEffect(() => {
    //  console.log(user)
  }, [])


  async function alterarSenha() {
    try {
      setLoading(true)
      if (senha2.length < 6) {
        setMsg("A senha deve conter pelo menos 6 caracteres!")
        return
      }

      if (senha2 !== senha3) {
        setMsg("As senhas não são iguais!")
        return
      }

      setLoading(true)
      await api.post('alterar-senha', {
        atual: senha1,
        nova: senha2,
        nova2: senha3,
        cpfcnpj: user.cpf
      })
      setIsModalOpen(false)
      addToast("Senha alterada com sucesso!", { appearance: 'success', autoDismiss: true });

    } catch (erro) {
      addToast(erro.message, { appearance: 'error', autoDismiss: true });
    }



  }


  function titleize(text) {
    var loweredText = text.toLowerCase().replace('  ', ' ').replace('   ', ' ').replace('    ', ' ')
      .replace('     ', ' ').replace('      ', ' ').replace('       ', ' ');
    var words = loweredText.split(" ");
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      var firstLetter = w[0];
      if (w.length > 2) {
        w = firstLetter.toUpperCase() + w.slice(1);
      } else {
        w = firstLetter + w.slice(1);
      }

      words[a] = w;
    }
    return words.join(" ");
  }

  function Download() {
    window.open(user.boleto);
  };



  return (<div style={{ backgroundColor: 'whitesmoke', minHeight: '100vh' }}>
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      backgroundColor: background_color, padding: 5
    }}>
      <Space direction='horizontal' style={{ display: 'flex' }}>
        <img style={{ height: 55, width: 55, marginTop: 'auto' }}
          alt='logo'
          src="https://crqx.org.br/downloads/4-decadas-quadrada-branco.png" />
        <div style={{ color: 'white', textAlign: 'left' }}>
          <strong>CRQ - 10ª REGIÃO</strong><br />
          <span style={{ fontSize: '1.2em', color: 'royalblue' }}>Ceará</span>

        </div>
      </Space>

      <Space direction='horizontal' >
        <Typography.Text style={{ color: 'white', fontSize: '1.1em' }}> {"Olá "}{titleize(user?.nome.split(' ')[0])} </Typography.Text>
        <Button size='small' onClick={() => {
          setUser(undefined)
          navigate("/registrado/login")
        }}>sair</Button>
      </Space>

    </div>
    <div style={{ display: 'flex', flexDirection: (isMobile ? 'column' : 'row'), justifyContent: 'space-around', marginTop: 10 }}>
      <div style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 5 }}>
        <Card>
          <Typography.Title level={5}>DADOS GERAIS</Typography.Title>
          <Divider style={{ margin: 0, marginBottom: 10 }} />
          <Row gutter={[12, 4]}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>NOME/RAZÃO SOCIAL</Col>
            <Col span={24}><Input size='small' value={user?.nome} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>TELEFONE</Col>
            <Col span={24}><Input size='small' value={user?.telefone} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>EMAIL</Col>
            <Col span={24}><Input size='small' value={user?.email} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>CPF/CNPJ</Col>
            <Col span={24}><Input size='small' value={user?.cpf} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>ENDEREÇO</Col>
            <Col span={24}><Input size='small' value={user?.endereco.concat(", ", user?.bairro)} /></Col>
          </Row>
        </Card>
      </div>
      <div style={{ marginLeft: isMobile ? 0 : 5, marginRight: isMobile ? 0 : 10 }}>
        <Card>
          <Typography.Title level={5}>CONSELHO REGIONAL DE QUÍMICA</Typography.Title>
          <Divider style={{ margin: 0, marginBottom: 10 }} />
          <Row gutter={[12, 4]}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>Nº CRQ</Col>
            <Col span={24}><Input size='small' value={user?.crq} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>DATA DE REGISTRO</Col>
            <Col span={24}><Input size='small' value={user?.datareg} /></Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>ÚLTIMO ANO PAGO</Col>
            <Col span={24}><Input size='small' value={user?.uap} /></Col>
            <Col span={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {
                "BOLETO"
              }
            </Col>
            <Col span={12} style={{ display: 'flex', flexDirection: 'row' }}>DECLARAÇÃO DE QUITAÇÃO</Col>
            <Col span={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <a href={user?.boleto} target="_blank" rel="noreferrer"> Download </a>
            </Col>
            <Col span={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {<Link to={"/declaracao"}> Emitir Declaração </Link>}
            </Col>

            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>ALTERAÇÃO DE SENHA</Col>
            <Col span={24} style={{ display: 'flex', flexDirection: 'row' }}>
              <Button size='small' type='primary' style={{ width: 130 }}
                onClick={() => {
                  setIsModalOpen(true)
                }}>Alterar</Button>
            </Col>

          </Row>
        </Card>
      </div>
    </div>

    <Modal title="Alteração de Senha" open={isModalOpen}
      width={300}
      afterClose={() => {
        setMsg(undefined)
        setSenha1('')
        setSenha2('')
        setSenha3('')
        setLoading(false)
      }}
      onOk={() => alterarSenha()}
      onCancel={() => setIsModalOpen(false)}>
      <Row gutter={[12, 4]}>
        <Col span={24}>Senha atual</Col>
        <Col span={24}>
          <Input.Password value={senha1} onChange={(e) => setSenha1(e.target.value)} />
        </Col>
        <Col span={24}>Nova senha</Col>
        <Col span={24}><Input.Password value={senha2} onChange={(e) => setSenha2(e.target.value)} /></Col>
        <Col span={24}>Confirme a senha</Col>
        <Col span={24}><Input.Password value={senha3} onChange={(e) => setSenha3(e.target.value)} /></Col>
        <Col span={24}>{(msg && loading === true) && <Alert message={msg}>
        </Alert>}
        </Col>
      </Row>
    </Modal>

  </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AreaRegistrado)