import { Typography } from 'antd'
import React from 'react'
import BannerPNG from '../images/banner-carta.png'
import LogoPNG from '../images/logo-novas-branco.png'

export const CartaUsuario = (props) => {
    return (<div style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'center', alignItems: 'center', width: '100%'
    }}>

        <Typography.Title level={1}>
            CARTA DE SERVIÇOS
        </Typography.Title>
        <img src={BannerPNG} alt='banner' style={{ width: '50%' }} />

        <img src={LogoPNG} alt='logo' style={{ width: 100, marginTop: 45 }} />

        <Typography.Title strong level={3}>
            Conselho Regional de Química da 10ª Região<br/>
          <span style={{fontStyle:'italic', fontWeight:'normal', fontSize:'0.7em'}}>  Desde 1983 catalisando o desenvolvimento da Química no Estado do Ceará </span>
        </Typography.Title>
        


    </div>
    )
}



export default CartaUsuario