import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';
import StringMask from "string-mask";
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../service/database';

const DELIMITERS = [".", "-", "/"];
const CNPJ_MASK = "00.000.000/0000-00";
const CPF_MASK = "000.000.000-00";

function removeTrailingCharIfFound(str, chars) {
    let result = str;

    chars.forEach(c => {
        if (str[str.length - 1] === c) {
            result = str.slice(0, str.length - 1);
        }
    });

    return result;
}

export const AreaRegistradoLogin = (props) => {

    let navigate = useNavigate()
    const [user, setUser] = useRecoilState(userState)
    const { addToast } = useToasts()
    const [cpfcnpj, setCpfCnpj] = useState('')
    const [senha, setSenha] = useState('')
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        console.log(user)
    }, [])



    function formatValue(str) {
        const unmaskedValue = str.replace(/[.\-\/]/g, "").trim();
        let mask = CPF_MASK;
        if (unmaskedValue.length > 11)
            mask = CNPJ_MASK;

        const formatted = StringMask.process(unmaskedValue, mask);
        return removeTrailingCharIfFound(formatted.result, DELIMITERS);
    }

    async function consulta() {
        try {

            if (!cpfcnpj || !senha) {
                setUser(undefined)
                addToast("Informe todos os campos!", { appearance: 'error', autoDismiss: true });
                return
            }

            setLoading(true)
            const result = await api.post('login', { cpfcnpj: cpfcnpj, senha: senha })
            console.log(result.data)
            if (result.data.token) {
                  setUser(result.data)
                  navigate("/registrado")
            } else {
                addToast("Erro ao efetuar login, verifique se dados informados estão corretos e tente novamente!",
                    { appearance: 'error', autoDismiss: true });
            }
        } catch (erro) {
            addToast(erro.message, { appearance: 'error', autoDismiss: true });
        }
        setLoading(false)
    }


    return (<div style={{
        display: 'flex', justifyContent: 'center',
        backgroundColor: 'whitesmoke', height: '100vh', maxHeight: '100vh'
    }}>
        <div style={{
            display: 'flex', flexDirection: 'column', backgroundColor: 'white',
            alignItems: 'center', maxWidth: 450, width: 450, justifyContent: 'center', margin: 'auto'
        }}>
            <div
                name="normal_login2"
                className="login-form"
                style={{ margin: 25 }}>
                <div>
                    <img style={{ height: 110, width: 110, marginTop: 10 }}
                        alt='logo'
                        src="https://crqx.org.br/downloads/4-decadas-quadrada-branco.png" />

                    <Typography.Title level={4}>Área do Registrado</Typography.Title>
                </div>

                <div name="crqx" style={{ marginTop: 20 }}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Informe o nº do CPF/CNPJ"
                        name='senha5'
                        autoComplete="off"
                        value={cpfcnpj}
                        onChange={(e) => setCpfCnpj(formatValue(e.target.value))} />
                </div>
                <div name="senha" style={{ marginTop: 10 }} >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        value={senha}
                        placeholder="Informe a senha"
                        onChange={(e) => setSenha(e.target.value)} />
                </div>

                <Button style={{ marginTop: 30 }}
                    type="primary"
                    block
                    loading={loading}
                    onClick={() => consulta()} className="login-form-button">
                    Acessar
                </Button>


                <div style={{ fontSize: '0.8em', marginTop: 15 }}>
                    Primeiro acesso?
                    <Link to={"/registrado/forgot"} className="login-form-forgot" style={{ marginLeft: 5 }}>
                        clique aqui
                    </Link>
                </div>

                <div style={{ fontSize: '0.8em', marginTop: 10 }}>
                    Ao clicar em "Entrar" você aceita a nossa
                    <a href='http://crqx.org.br/politica-de-privacidade/' className="login-form-forgot" style={{ marginLeft: 5 }}>
                        Política de Privacidade
                    </a>
                </div>

                <div style={{ fontSize: '0.6em', marginTop: 10 }}>
                    1983-2023 (ver 0.7)
                </div>


            </div>
        </div>
    </div>
    );


}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AreaRegistradoLogin)