import './App.css';
import { Routes, Route, BrowserRouter, Navigate, useNavigate, useLocation } from "react-router-dom";

import { ConsultaFirmaView } from './view/consultaFirma'
import { ConsultaProfissionalView } from './view/consultaProfissional'
import { HomeView } from './view/home'
import { ConsultaART } from './view/consultaART'
import { Login } from './view/login';
import { Declaracao } from './view/declaracao'
import { ValidarDeclaracao } from './view/validarDeclaracao';
import { ConsultaCRT } from './view/ConsultaCRT'
import { AreaRegistradoLogin } from './view/AreaRegistradoLogin';
import { AreaRegistradoForgot } from './view/AreaRegistradoForgot';
import { AreaRegistrado } from './view/AreaRegistrado';
import { RecoilRoot, atom, useRecoilValue } from 'recoil'
import { userState } from './service/database'

import { ToastProvider } from 'react-toast-notifications';
import {CartaUsuario} from './view/cartaUsuario';

function Rotas() {
  return <BrowserRouter>
    <div className="App">
      <Routes>
        <Route index element={<HomeView />} />
        <Route path="login" element={<Login />} />
        <Route path="consulta-profissional" element={<ConsultaProfissionalView />} />
        <Route path="consulta-firma" element={<ConsultaFirmaView />} />
        <Route path='consulta-art' element={<ConsultaART />} />
        <Route path='declaracao' element={<Declaracao />} />
        <Route path='declaracao-autenticar' element={<ValidarDeclaracao />} />
        <Route path='consulta-CRT' element={<ConsultaCRT />} />
        <Route path='registrado/login' element={<AreaRegistradoLogin />} />
        <Route path='registrado/forgot' element={<AreaRegistradoForgot />} />
        <Route path='carta-ao-usuario' element={<CartaUsuario />} />
        <Route path='registrado'
          element={
            <RequireAuth>
              <AreaRegistrado />
            </RequireAuth>} />
        {/* Using path="*"" means "match anything", so this route acts like a catch-all for URLs that 
    we don't have explicit routes for. */}
        <Route path="*" element={<HomeView />} />
      </Routes>
    </div>
  </BrowserRouter>
}



function RequireAuth({ children }) {
  let user = useRecoilValue(userState);
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/registrado/login" state={{ from: location }} replace />;
  }

  return children;
}



function App() {
  return (<ToastProvider>
    <RecoilRoot>
      <Rotas />
    </RecoilRoot>
  </ToastProvider>

  );
}




export default App;
