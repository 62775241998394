import {atom} from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userState = atom({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom],
  });


  

  