import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Layout, Typography, Alert, Result, Space } from 'antd'
import { ProfileOutlined, HomeOutlined } from '@ant-design/icons';
import { LeftBar } from '../componentes/leftBar';
import StringMask from "string-mask";
import api from '../service/axios'
import { useToasts } from 'react-toast-notifications';


const DELIMITERS = [".", "-", "/"];
const CNPJ_MASK = "00.000.000/0000-00";

const { Content } = Layout

function removeTrailingCharIfFound(str, chars) {
  let result = str;

  chars.forEach(c => {
    if (str[str.length - 1] === c) {
      result = str.slice(0, str.length - 1);
    }
  });

  return result;
}

function formatValue(str) {
  const unmaskedValue = str.replace(/[.\-\/]/g, "").trim();
  let mask = CNPJ_MASK;

  const formatted = StringMask.process(unmaskedValue, mask);
  return removeTrailingCharIfFound(formatted.result, DELIMITERS);
}


export const ConsultaFirmaView = (props) => {

  const [cpf, setCpf] = useState('')
  const [nome, setNome] = useState('')
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const [data, setData] = useState(undefined)

  async function consulta() {
    try {
      setLoading(true)
      const result = await api.post('consulta-firma', { razao: nome, cnpj: cpf })
      console.log(result.data)
      setData(result.data)
      if (result.data.length === 0) {
        addToast("Não foi localizado nenhum registro!", { appearance: 'error', autoDismiss: true });
      }
    } catch (erro) {
      addToast(erro.message, { appearance: 'error', autoDismiss: true });
    }
    setLoading(false)
  }


  function renderConsultar() {
    return <React.Fragment>
      <Typography.Title level={3}>Consulta Registro de Firma</Typography.Title>
      <br />
      <Input placeholder='Nº do CNPJ' style={{ textAlign: 'center' }}
        value={cpf}
        onChange={event => {
          setCpf(formatValue(event.target.value))
        }} />
      OU
      <Input placeholder='RAZÃO SOCIAL' style={{ textAlign: 'center' }}
        value={nome}
        onChange={event => {
          setNome(nome)
        }} />
      
      <Button type="primary" style={{marginTop:10}} loading={loading} block onClick={() => consulta()}>
        Consultar
      </Button>
     
      {
        data?.length === 0 && <Alert style={{marginTop:15}} message="Não foi localizado nenhum registro para os dados informados." type="error" />
      }
    </React.Fragment>
  }


  function renderResultado() {
    return <React.Fragment>
      <Result
        status="success"
        title="Firma Loacalizada"
        subTitle={<div> </div>}
        extra={[
          <Alert type='success' description={
            <Space direction='vertical'>
              <Space direction={'horizontal'}>
                <ProfileOutlined />
                <strong> {data[0].CRQ} </strong>
              </Space>
              <Space direction={'horizontal'}>
              <HomeOutlined />
                <strong> {data[0].RAZAO} </strong>
              </Space>              
            </Space>
          }>

          </Alert>,
          <br/>,
          <Button key="buy" onClick={() => setData(undefined)}>Voltar</Button>
        ]}
      />
    </React.Fragment>
  }


  return (
    <Layout style={{ height: '100vh' }}>
      <Layout>
        <LeftBar selected={"2"}/>
        <Content
          style={{
            margin: '24px 16px 0'
          }}>
          <div
            style={{
              justifyContent: 'center',
              padding: 24,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>

            <div style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column',
              maxWidth: 500, alignItems: 'center'
            }}>
              {data?.length > 0 ? renderResultado() : renderConsultar()}
            </div>
            <br /><br />


          </div>
        </Content>

      </Layout>
    </Layout>


  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaFirmaView)