import React from 'react'
import { connect } from 'react-redux'
import { Divider, Layout, Typography, Space, Card, Row, Col } from 'antd'
import { LeftBar } from '../componentes/leftBar';
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect'
const { Content } = Layout

export const HomeView = (props) => {



  return (
    <Layout style={{ height: '100vh' }}>
      <Layout>
        <LeftBar />
        <Content
          style={{
            margin: '24px 16px 0',
            display: 'flex',
            flexDirection: 'column',
            flexFlow: 'column',
            flexGrow: 1
          }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: 'white',
              flexGrow: 1,
              overflow: 'auto'
            }}>
            {isMobile && <img style={{ height: 140, width: 140 }}
              alt='logo'
              src="http://crqx.org.br/downloads/4-decadas-quadrada-branco.png" />}

            <Typography.Title level={3}>
              Conselho Regional de Química da 10ª Região
            </Typography.Title>
            <Typography.Text style={{ color: 'royalblue', fontSize: '1.2em' }}>
              sistema de serviços online
            </Typography.Text>
            <Divider />

            <Row gutter={[8, 8]}>
              <Col xs={24} md={12} lg={6}>
                <Link to={'/registrado/login'}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center',
                    backgroundColor: '#1E90FF'
                  }}>
                    <Typography.Text style={{ fontWeight: 600, color: 'white' }}>Área do Registrado</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <a href='http://crqx.org.br/carta-ao-usuario-crqx/' target='_blank' rel="noreferrer">
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center',
                    backgroundColor: '#7B68EE'
                  }}>
                    <Typography.Text style={{ fontWeight: 600, color: 'white' }}>Carta ao Usuário</Typography.Text>
                  </Card>
                </a>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/consulta-profissional"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Consulta Profissional</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/consulta-firma"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Consulta Firma</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/consulta-art"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Consulta ART</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/declaracao"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Declaração de Quitação</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/declaracao-autenticar"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Validar Declaração de Quitação</Typography.Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Link to={"/consulta-crt"}>
                  <Card style={{
                    display: 'flex', height: '100%', flexDirection: 'column',
                    flexGrow: 1, justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Typography.Text style={{ fontWeight: 600 }}>Consulta Comunicação de Responsabilidade Técnica</Typography.Text>
                  </Card>
                </Link>
              </Col>


            </Row>
          </div>
          <div style={{ marginTop: 10, marginBottom: 5 }}>
            <div style={{ fontWeight: 600 }}>
              Conselho Regional de Quimica da 10ª Região
            </div>
            <div style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
              Desde 1983 Catalisando o Desenvolvimento da Química no Estado do Ceará.
            </div>
          </div>
        </Content>
      </Layout>
    </Layout >
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)